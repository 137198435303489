import ApplicationController from 'modules/application_controller';
import Player from 'modules/player/player';

export default class extends ApplicationController {
  initialize() {}

  connect() {
    this.initPlayer();
  }

  initPlayer() {
    if (this.player) {
      return;
    }

    this.player = new Player(this.element.id, this.playerOptions);
  }

  get playerOptions() {
    return { offline: true };
  }
}
