import ApplicationController from 'modules/application_controller';
import shave from 'shave';

const TITLE_LINES = 2;

export default class extends ApplicationController {
  static get targets() {
    return ['title'];
  }

  initialize() {
    this.props = {
      originalTitle: '',
    };
  }

  connect() {
    this.originalTitle = this.titleTarget.textContent;
    this.shaveTitle();
  }

  shaveTitle() {
    this.titleTarget.textContent = this.originalTitle;
    shave(this.titleTarget, 19 * TITLE_LINES, { spaces: false });
  }

  get originalTitle() {
    return this.props.originalTitle;
  }

  set originalTitle(value) {
    this.props.originalTitle = value;
  }
}
